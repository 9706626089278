//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-164:_6192,_7876,_5080,_4592,_7968,_5840,_4720,_9844;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-164')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-164', "_6192,_7876,_5080,_4592,_7968,_5840,_4720,_9844");
        }
      }catch (ex) {
        console.error(ex);
      }