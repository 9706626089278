
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('div', { 'className': 'col' }, _createElement('div', { 'className': 'product__card mb-20 product__card--style_1 product--corner-radius-true color-background-2' }, _createElement('div', { 'className': 'product__card__thumbnail card--client-height' }, _createElement('span', { 'className': 'product__card__badges' }, this.out_of_stock ? _createElement('span', {
                    'className': 'badge badge--bottom-left color-inverse',
                    'aria-hidden': 'true',
                    'key': '238'
                }, '\n          Sold out\n        ') : null), _createElement('a', {
                    'href': this.url,
                    'className': 'd-block product__media_thumbnail product__card--link'
                }, _createElement('div', { 'className': 'media media--transparent media--adapt media--hover-effect' }, _createElement('img', {
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError,
                    'className': 'motion-reduce'
                }))), _createElement('wishlist-item', {}, _createElement('button', {
                    'wishlist-button': true,
                    'className': 'wishlist__button product__card--wishlist-btn product--tooltip',
                    'type': 'button',
                    'aria-label': 'Add to wishlist',
                    'data-product-handle': this.handle
                }, _createElement('span', {
                    'title': 'Add to wishlist',
                    'className': 'add__wishlist'
                }, _createElement('svg', {
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'viewBox': '0 0 512 512'
                }, _createElement('path', {
                    'fill': 'currentColor',
                    'd': 'M462.3 62.7c-54.5-46.4-136-38.7-186.6 13.5L256 96.6l-19.7-20.3C195.5 34.1 113.2 8.7 49.7 62.7c-62.8 53.6-66.1 149.8-9.9 207.8l193.5 199.8c6.2 6.4 14.4 9.7 22.6 9.7 8.2 0 16.4-3.2 22.6-9.7L472 270.5c56.4-58 53.1-154.2-9.7-207.8zm-13.1 185.6L256.4 448.1 62.8 248.3c-38.4-39.6-46.4-115.1 7.7-161.2 54.8-46.8 119.2-12.9 142.8 11.5l42.7 44.1 42.7-44.1c23.2-24 88.2-58 142.8-11.5 54 46 46.1 121.5 7.7 161.2z'
                }))), _createElement('span', { 'className': 'loading__wishlist' }), _createElement('span', {
                    'title': 'Remove from wishlist',
                    'className': 'remove__wishlist'
                }, _createElement('svg', {
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'viewBox': '0 0 512 512'
                }, _createElement('path', {
                    'fill': 'currentColor',
                    'd': 'M417.84 448a15.94 15.94 0 01-11.35-4.72L40.65 75.26a16 16 0 0122.7-22.56l365.83 368a16 16 0 01-11.34 27.3zM364.92 80c-48.09 0-80 29.55-96.92 51-16.88-21.48-48.83-51-96.92-51a107.37 107.37 0 00-31 4.55L168 112c22.26 0 45.81 9 63.94 26.67a123 123 0 0121.75 28.47 16 16 0 0028.6 0 123 123 0 0121.77-28.51C322.19 121 342.66 112 364.92 112c43.15 0 78.62 36.33 79.07 81 .54 53.69-22.75 99.55-57.38 139.52l22.63 22.77c3-3.44 5.7-6.64 8.14-9.6 40-48.75 59.15-98.8 58.61-153C475.37 130.52 425.54 80 364.92 80zM268 432C180.38 372.51 91 297.6 92 193a83.69 83.69 0 012.24-18.39L69 149.14a115.1 115.1 0 00-9 43.49c-.54 54.22 18.63 104.27 58.61 153 18.77 22.87 52.8 59.45 131.39 112.8a31.84 31.84 0 0036 0c20.35-13.81 37.7-26.5 52.58-38.11l-22.66-22.81C300.25 409.6 284.09 421.05 268 432z'
                }))), _createElement('div', { 'className': 'product--tooltip-label tooltip--left' }, _createElement('span', { 'className': 'product__card--add-wishlist' }, 'Add to wishlist'), _createElement('span', { 'className': 'product__card--remove-wishlist' }, 'Remove from wishlist'))))), _createElement('div', { 'className': 'product__card__content text-left product--card-spacing-true' }, _createElement('div', mergeProps({ 'className': 'product__vendor' }, { dangerouslySetInnerHTML: { __html: this.vendor } })), _createElement('h3', { 'className': 'product__card__title h6' }, _createElement('a', mergeProps({
                    'className': 'product__card-title--link',
                    'href': this.url
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'price product__card__price justify-content-start price--sold-out' }, _createElement('dl', {}, _createElement('div', { 'className': 'price__regular' }, _createElement('dd', {}, _createElement('span', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.price)))), !this.out_of_stock && this.on_sale ? _createElement('div', {
                    'className': 'price__sale',
                    'key': '3569'
                }, _createElement('dd', { 'className': 'price__compare' }, _createElement('s', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.compare_at_price))), _createElement('dd', {}, _createElement('span', { 'className': 'price-item price-item--sale' }, this.formatPrice(this.price)))) : null)), _createElement('div', { 'className': 'product-card-action-buttons' }, _createElement('div', { 'className': 'product__cart--wrapper color-foreground-accent-2' }, this.variant_ids.length === 1 ? _createElement('product-form', {
                    'key': '4109',
                    'class': 'cm_product-form'
                }, _createElement('form', {
                    'action': '/cart/add',
                    'method': 'post',
                    'encType': 'multipart/form-data',
                    'data-type': 'add-to-cart-form'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids[0]
                }), _createElement('button', {
                    'type': 'submit',
                    'name': 'add',
                    'className': 'product__card--action-btn product__card--cart-btn product--tooltip'
                }, [_createElement('svg', {
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'className': 'ionicon',
                        'viewBox': '0 0 512 512',
                        'key': '45130'
                    }, _createElement('title', {}, 'Cart'), _createElement('circle', {
                        'cx': '176',
                        'cy': '416',
                        'r': '16',
                        'fill': 'none',
                        'stroke': 'currentColor',
                        'strokeLinecap': 'round',
                        'strokeLinejoin': 'round',
                        'strokeWidth': '32'
                    }), _createElement('circle', {
                        'cx': '400',
                        'cy': '416',
                        'r': '16',
                        'fill': 'none',
                        'stroke': 'currentColor',
                        'strokeLinecap': 'round',
                        'strokeLinejoin': 'round',
                        'strokeWidth': '32'
                    }), _createElement('path', {
                        'fill': 'none',
                        'stroke': 'currentColor',
                        'strokeLinecap': 'round',
                        'strokeLinejoin': 'round',
                        'strokeWidth': '32',
                        'd': 'M48 80h64l48 272h256'
                    }), _createElement('path', {
                        'd': 'M160 288h249.44a8 8 0 007.85-6.43l28.8-144a8 8 0 00-7.85-9.57H128',
                        'fill': 'none',
                        'stroke': 'currentColor',
                        'strokeLinecap': 'round',
                        'strokeLinejoin': 'round',
                        'strokeWidth': '32'
                    }))], _createElement('span', { 'className': 'cart__buton--label' }, this.out_of_stock ? 'Sold out' : 'Add to cart'), _createElement('div', { 'className': 'product--tooltip-label tooltip--top desktop--tooltip-disable' }, '\n                  ', this.out_of_stock ? 'Sold out' : 'Add to cart', '\n                ')))) : null, this.variant_ids.length > 1 ? _createElement('quick-view-modal', { 'key': '5626' }, _createElement('button', {
                    'aria-haspopup': 'dialog',
                    'type': 'button',
                    'className': 'product__quick_view product__card--action-btn product__card--cart-btn product--tooltip',
                    'data-product-handle': this.handle,
                    'aria-label': 'quick view'
                }, [_createElement('svg', {
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'className': 'ionicon',
                        'viewBox': '0 0 512 512',
                        'key': '59110'
                    }, _createElement('title', {}, 'Cart'), _createElement('circle', {
                        'cx': '176',
                        'cy': '416',
                        'r': '16',
                        'fill': 'none',
                        'stroke': 'currentColor',
                        'strokeLinecap': 'round',
                        'strokeLinejoin': 'round',
                        'strokeWidth': '32'
                    }), _createElement('circle', {
                        'cx': '400',
                        'cy': '416',
                        'r': '16',
                        'fill': 'none',
                        'stroke': 'currentColor',
                        'strokeLinecap': 'round',
                        'strokeLinejoin': 'round',
                        'strokeWidth': '32'
                    }), _createElement('path', {
                        'fill': 'none',
                        'stroke': 'currentColor',
                        'strokeLinecap': 'round',
                        'strokeLinejoin': 'round',
                        'strokeWidth': '32',
                        'd': 'M48 80h64l48 272h256'
                    }), _createElement('path', {
                        'd': 'M160 288h249.44a8 8 0 007.85-6.43l28.8-144a8 8 0 00-7.85-9.57H128',
                        'fill': 'none',
                        'stroke': 'currentColor',
                        'strokeLinecap': 'round',
                        'strokeLinejoin': 'round',
                        'strokeWidth': '32'
                    }))], _createElement('span', { 'className': 'cart__buton--label' }, 'Select option'), _createElement('div', { 'className': 'product--tooltip-label tooltip--top desktop--tooltip-disable' }, 'Select option'))) : null), _createElement('compare-item', {}, _createElement('button', {
                    'className': 'compare__button wishlist__button product__card--action-btn product__card--action-btn-icon product--tooltip',
                    'type': 'button',
                    'aria-label': 'Add to compare',
                    'data-product-handle': this.handle,
                    'data-product-title': this.removeHTML(this.titile)
                }, _createElement('span', {
                    'title': 'Add to compare',
                    'className': 'add__wishlist'
                }, _createElement('svg', {
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'className': 'icon icon-tabler icon-tabler-switch',
                    'width': '44',
                    'height': '44',
                    'viewBox': '0 0 24 24',
                    'strokeWidth': '1.7',
                    'stroke': 'currentColor',
                    'fill': 'none',
                    'strokeLinecap': 'round',
                    'strokeLinejoin': 'round'
                }, _createElement('path', {
                    'stroke': 'none',
                    'd': 'M0 0h24v24H0z',
                    'fill': 'none'
                }), _createElement('polyline', { 'points': '15 4 19 4 19 8' }), _createElement('line', {
                    'x1': '14.75',
                    'y1': '9.25',
                    'x2': '19',
                    'y2': '4'
                }), _createElement('line', {
                    'x1': '5',
                    'y1': '19',
                    'x2': '9',
                    'y2': '15'
                }), _createElement('polyline', { 'points': '15 19 19 19 19 15' }), _createElement('line', {
                    'x1': '5',
                    'y1': '5',
                    'x2': '19',
                    'y2': '19'
                }))), _createElement('span', { 'className': 'loading__wishlist' }), _createElement('span', {
                    'title': 'Remove from compare',
                    'className': 'remove__wishlist'
                }, _createElement('svg', {
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'width': '24',
                    'height': '24',
                    'viewBox': '0 0 24 24',
                    'fill': 'none',
                    'stroke': 'currentColor',
                    'strokeWidth': '1.5',
                    'strokeLinecap': 'round',
                    'strokeLinejoin': 'round',
                    'className': 'feather feather-check-circle'
                }, _createElement('path', { 'd': 'M22 11.08V12a10 10 0 1 1-5.93-9.14' }), _createElement('polyline', { 'points': '22 4 12 14.01 9 11.01' }))), _createElement('div', { 'className': 'product--tooltip-label tooltip--top' }, _createElement('span', { 'className': 'product__card--add-wishlist' }, 'Add to compare'), _createElement('span', { 'className': 'product__card--remove-wishlist' }, 'Remove from compare')))), _createElement('quick-view-modal', {}, _createElement('button', {
                    'aria-haspopup': 'dialog',
                    'type': 'button',
                    'className': 'product__quick_view product__card--action-btn product__card--action-btn-icon product--tooltip',
                    'data-product-handle': this.handle,
                    'aria-label': 'quick view'
                }, _createElement('svg', {
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'width': '24',
                    'height': '24',
                    'viewBox': '0 0 24 24',
                    'fill': 'none',
                    'stroke': 'currentColor',
                    'strokeWidth': '2',
                    'strokeLinecap': 'round',
                    'strokeLinejoin': 'round',
                    'className': 'feather feather-eye'
                }, _createElement('path', { 'd': 'M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z' }), _createElement('circle', {
                    'cx': '12',
                    'cy': '12',
                    'r': '3'
                })), _createElement('div', { 'className': 'product--tooltip-label tooltip--top' }, 'Quick view')))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'row row-cols-lg-3 row-cols-md-3  row-cols-2 cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []