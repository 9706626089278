
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function homeVehicleWidgetRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n        ', entry.value, '\n      ');
                }
                return _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown select__field_form facets--filter-group' }, _createElement('div', { 'className': 'filter__tooltip--wrapper' }, _createElement('label', {}, this.title), _createElement('button', {
                    'type': 'button',
                    'className': 'tooltip filter--product-tooltip-wrapper',
                    'aria-label': 'Select Make'
                }, _createElement('svg', {
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'viewBox': '0 0 24 24',
                    'fill': 'none',
                    'stroke': 'currentColor',
                    'strokeWidth': '1.5',
                    'strokeLinecap': 'round',
                    'strokeLinejoin': 'round',
                    'className': 'feather feather-help-circle'
                }, _createElement('circle', {
                    'cx': '12',
                    'cy': '12',
                    'r': '10'
                }), _createElement('path', { 'd': 'M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3' }), _createElement('line', {
                    'x1': '12',
                    'y1': '17',
                    'x2': '12.01',
                    'y2': '17'
                })), _createElement('div', { 'className': 'filter--tooltip-label tooltip--top' }, _createElement('span', { 'className': 'filter--tooltip-text' }, 'Select ', this.title)))), _createElement('div', { 'className': 'select__field_form' }, _createElement.apply(this, [
                    'select',
                    {
                        'className': 'cm_select_inner-select cm_select__pretty',
                        'onChange': this.onChange,
                        'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                        'disabled': this.disabled
                    },
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n        ', this.title, '\n      ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    _map(this.entries, repeatEntry1.bind(this))
                ]), _createElement('svg', {
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'viewBox': '0 0 10 6'
                }, _createElement('use', { 'href': '#icon-caret' }))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__home vertical--filter' }, this.template === 'active' ? _createElement('div', {
        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
        'key': '75'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container facets--filter-group cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'search--filter-button' }, _createElement('button', {
        'className': 'button button--medium button--full-width',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle
    }, _createElement('span', { 'className': 'button--search-with-icon' }, _createElement('svg', {
        'className': 'icon icon-search',
        'aria-hidden': 'true',
        'focusable': 'false',
        'role': 'presentation'
    }, _createElement('use', { 'href': '#icon-search' })), '\n            Search\n          '))))) : null);
}
        export const componentNames = []